.containerInd {
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerInd .flag {
  width: 400px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerInd .bar {
  height: 200px;
  width: calc(100% / 20);
  background: #0984e3;
  vertical-align: bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  content: "";
}
.containerInd .bar-1 {
  animation: animate-1 3s ease-in-out infinite;
  animation-delay: 0.05s;
}
@keyframes animate-1 {
  40% {
    transform: translateY(-5px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-2 {
  animation: animate-2 3s ease-in-out infinite;
  animation-delay: 0.1s;
}
@keyframes animate-2 {
  40% {
    transform: translateY(-7px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-3 {
  animation: animate-3 3s ease-in-out infinite;
  animation-delay: 0.15s;
}
@keyframes animate-3 {
  40% {
    transform: translateY(-9px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-4 {
  animation: animate-4 3s ease-in-out infinite;
  animation-delay: 0.2s;
}
@keyframes animate-4 {
  40% {
    transform: translateY(-11px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-5 {
  animation: animate-5 3s ease-in-out infinite;
  animation-delay: 0.25s;
}
@keyframes animate-5 {
  40% {
    transform: translateY(-13px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-6 {
  animation: animate-6 3s ease-in-out infinite;
  animation-delay: 0.3s;
}
@keyframes animate-6 {
  40% {
    transform: translateY(-15px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-7 {
  animation: animate-7 3s ease-in-out infinite;
  animation-delay: 0.35s;
}
@keyframes animate-7 {
  40% {
    transform: translateY(-17px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-8 {
  animation: animate-8 3s ease-in-out infinite;
  animation-delay: 0.4s;
}
@keyframes animate-8 {
  40% {
    transform: translateY(-19px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-9 {
  animation: animate-9 3s ease-in-out infinite;
  animation-delay: 0.45s;
}
@keyframes animate-9 {
  40% {
    transform: translateY(-21px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-10 {
  animation: animate-10 3s ease-in-out infinite;
  animation-delay: 0.5s;
}
@keyframes animate-10 {
  40% {
    transform: translateY(-23px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-11 {
  animation: animate-11 3s ease-in-out infinite;
  animation-delay: 0.55s;
}
@keyframes animate-11 {
  40% {
    transform: translateY(-25px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-12 {
  animation: animate-12 3s ease-in-out infinite;
  animation-delay: 0.6s;
}
@keyframes animate-12 {
  40% {
    transform: translateY(-27px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-13 {
  animation: animate-13 3s ease-in-out infinite;
  animation-delay: 0.65s;
}
@keyframes animate-13 {
  40% {
    transform: translateY(-29px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-14 {
  animation: animate-14 3s ease-in-out infinite;
  animation-delay: 0.7s;
}
@keyframes animate-14 {
  40% {
    transform: translateY(-31px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-15 {
  animation: animate-15 3s ease-in-out infinite;
  animation-delay: 0.75s;
}
@keyframes animate-15 {
  40% {
    transform: translateY(-33px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-16 {
  animation: animate-16 3s ease-in-out infinite;
  animation-delay: 0.8s;
}
@keyframes animate-16 {
  40% {
    transform: translateY(-35px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-17 {
  animation: animate-17 3s ease-in-out infinite;
  animation-delay: 0.85s;
}
@keyframes animate-17 {
  40% {
    transform: translateY(-37px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-18 {
  animation: animate-18 3s ease-in-out infinite;
  animation-delay: 0.9s;
}
@keyframes animate-18 {
  40% {
    transform: translateY(-39px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-19 {
  animation: animate-19 3s ease-in-out infinite;
  animation-delay: 0.95s;
}
@keyframes animate-19 {
  40% {
    transform: translateY(-41px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-20 {
  animation: animate-20 3s ease-in-out infinite;
  animation-delay: 1s;
}
@keyframes animate-20 {
  40% {
    transform: translateY(-43px) scaleY(1.1) rotateX(-10deg);
  }
}
.containerInd .bar-1 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-position: 0px 0px !important;
  background-size: cover;
}
.containerInd .bar-1 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: 0px 0px;
}
.containerInd .bar-2 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -20px 0px;
}
.containerInd .bar-3 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -40px 0px;
}
.containerInd .bar-4 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -60px 0px;
}
.containerInd .bar-5 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -80px 0px;
}
.containerInd .bar-6 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -100px 0px;
}
.containerInd .bar-7 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -120px 0px;
}
.containerInd .bar-8 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -140px 0px;
}
.containerInd .bar-9 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -160px 0px;
}
.containerInd .bar-10 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -180px 0px;
}
.containerInd .bar-11 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -200px 0px;
}
.containerInd .bar-12 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -220px 0px;
}
.containerInd .bar-13 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -240px 0px;
}
.containerInd .bar-14 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -260px 0px;
}
.containerInd .bar-15 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -280px 0px;
}
.containerInd .bar-16 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -300px 0px;
}
.containerInd .bar-17 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -320px 0px;
}
.containerInd .bar-18 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -340px 0px;
}
.containerInd .bar-19 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -360px 0px;
}
.containerInd .bar-20 {
  background: url(https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg) no-repeat;
  background-size: cover;
  background-position: -380px 0px;
}