.linkedin{
  position: absolute;
  background-color: rgba(110, 110, 110, 0.486);
  width: 50px;
  height: 50px;
  bottom: 10%;
  right: 10%;
  transition: all 0.3s;
}

.linkedin:hover{
  cursor: pointer;
  background-color: #ff660088;
}

.ket{
  width: 100%;
  max-width: 50px !important;
  /* filter: grayscale(1); */
  opacity: 0.5;
  margin: 0 3px;
}


.ket:hover{
  filter: grayscale(0);
  opacity: 1;
}
