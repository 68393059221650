@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

@layer base {
  html {
    font-family: 'Manrope', sans-serif;
    background-color: #1f2937;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
  }

  .bg-img{
    background-image: url("./assets/back.svg") !important;
    background-size: 900px !important;
    background-repeat: repeat !important;
  }

  .bg-img-5{
    background-image: url("./assets/back.svg") !important;
    opacity: 0.5;
    background-size: 900px !important;
    background-repeat: repeat !important;
  }
  ::-moz-selection {
    /* Code for Firefox */
    color: #fff;
    background: #ff8400;
  }

  ::selection {
    color: #fff;
    background: #ff8400;
  }

  ::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); */
    background-color: #1f2937;
    border: #2b394d 1px solid;
  }

  ::-webkit-scrollbar {
    width: 15px;
    background-color: #1f2937;
  }

  ::-webkit-scrollbar-thumb {
    border: #4e5c71 1px solid;
    /* -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, .3); */
    background-color: #333c4a;
  }
}