:root {
  --general-color: #ececec;
  --contrast-color: #fbfbfb;
  --background: #fffb00;
  --background2: #ff0030;
  --transition-duration: 0.3s;
  --background-gradient: linear-gradient(-45deg,
      var(--background),
      var(--background2),
      var(--background));
  color: var(--general-color);
  --neon-text-color: #f40;
  --neon-border-color: #08f;
}

.bg-ke {
  left: -12vh;
  top: -65vh;
  width: calc(100% + 12vh);
  overflow: hidden;
  position: absolute;
  font-size: 150vh;
  font-weight: 900;
  z-index: 0;
  color: rgba(133, 39, 5, 0.2);
}

.texto {
  transition: var(--transition-duration);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.texto h1 {
  margin: 0;
  font-size: 5vw;
  font-weight: 800;
  z-index: 1;
}

.texto h1.ke {
  margin: 0;
  font-size: 20vw;
  font-weight: 800;
  margin-bottom: -5vw;
  margin-top: -5vw;
}

.banner {
  min-height: calc(100vh - 60px);
  overflow: hidden;
}

.texto h1 .container {
  margin: 1rem;
  position: relative;
}

.texto h1 .container .word {
  text-rendering: optimizeLegibility !important;
  white-space: pre;
  left: 50%;
  transform: translate(-50%, -10%);
  opacity: 0;
  display: block;
  top: 0;
  position: absolute;
}

.texto p {
  margin: 1rem 0;
}

.letter {
  text-rendering: optimizeLegibility !important;
  display: inline-block;
  position: relative;
  transform: translateZ(2vw);
  transform-origin: 50% 50% 2vw;
}

.letter.out {
  transform: rotateX(90deg);
  transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.letter.behind {
  transform: rotateX(-90deg);
}

.letter.in {
  transform: rotateX(0deg);
  transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes flow {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@-webkit-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-o-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-moz-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@keyframes gradient {
  0% {
    background-position-y: 100vh;
  }

  100% {
    background-position-y: -100vh;
  }
}

@keyframes grain {
  0% {
    -webkit-transform: translate(-10%, 10%);
    -moz-transform: translate(-10%, 10%);
    transform: translate(-10%, 10%);
  }

  10% {
    -webkit-transform: translate(-25%, 0%);
    -moz-transform: translate(-25%, 0%);
    transform: translate(-25%, 0%);
  }

  20% {
    -webkit-transform: translate(-30%, 10%);
    -moz-transform: translate(-30%, 10%);
    transform: translate(-30%, 10%);
  }

  30% {
    -webkit-transform: translate(-30%, 30%);
    -moz-transform: translate(-30%, 30%);
    transform: translate(-30%, 30%);
  }

  40% {
    -webkit-transform: translate();
    -moz-transform: translate();
    transform: translate();
  }

  50% {
    -webkit-transform: translate(-15%, 10%);
    -moz-transform: translate(-15%, 10%);
    transform: translate(-15%, 10%);
  }

  60% {
    -webkit-transform: translate(-20%, 20%);
    -moz-transform: translate(-20%, 20%);
    transform: translate(-20%, 20%);
  }

  70% {
    -webkit-transform: translate(-5%, 20%);
    -moz-transform: translate(-5%, 20%);
    transform: translate(-5%, 20%);
  }

  80% {
    -webkit-transform: translate(-25%, 5%);
    -moz-transform: translate(-25%, 5%);
    transform: translate(-25%, 5%);
  }

  90% {
    -webkit-transform: translate(-30%, 25%);
    -moz-transform: translate(-30%, 25%);
    transform: translate(-30%, 25%);
  }

  100% {
    -webkit-transform: translate(-10%, 10%);
    -moz-transform: translate(-10%, 10%);
    transform: translate(-10%, 10%);
  }
}

.abt-back {
  background-image: url("../assets/back.svg");
  background-size: cover;
}

.team-img {
  width: 100%;
  min-width: 600px;
  margin-top: -8% !important;
  position: relative;
  z-index: 0;
}

.g-rwe a {
  display: none !important;
}


.c-logo {
  max-width: 150px !important;
  height: auto;
  max-height: 100px;
  filter: grayscale(1);
  opacity: 0.5;
}

.c-logo:hover{
  filter: grayscale(0);
  opacity: 1;
}
.constant-tilt-shake {
  animation: tilt-shaking 2s infinite;
}

.constant-tilt-shake:hover {
  animation: none;
}

@keyframes tilt-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  3% {
    transform: translate(2px, -4px) rotate(5deg);
  }
  6% {
    transform: translate(0, 0) rotate(0eg);
  }
  9% {
    transform: translate(-4px, -4px) rotate(-5deg);
  }
  12% {
    transform: translate(0, 0) rotate(0deg);
  }
  15% {
    transform: translate(4px, -4px) rotate(5deg);
  }
  18% {
    transform: translate(0, 0) rotate(0eg);
  }
  21% {
    transform: translate(-4px, -4px) rotate(-5deg);
  }
  24% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}



