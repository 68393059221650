.abt-back {
  background-image: url("../assets/back.svg");
  background-size: cover;
}

.team-img {
  width: 100%;
  min-width: 600px;
  margin-top: -8% !important;
  position: relative;
  z-index: 0;
}

.glow-text {
  animation-name: glow;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes glow {
  from {
    text-shadow: 0px 0px 5px #ffffff8a, 0px 0px 5px #00c8ffad;
  }
  to {
    text-shadow: 0px 0px 10px #ffffff8e, 0px 0px 10px #00c8ff92;
  }
}
